@import "../../../scss/settings/variable";
@import "./fields/dateRange";
@import "./fields/numberRange";

$cell-height: 55px;
.smart-table-wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  font-weight: 300;
  font-size: 14px;
  color: black;
  border-radius: 5px;
  box-shadow: 0 2px 7px 0 rgba(133, 133, 133, 0.55);
  background-color: $white;
  overflow: scroll;
  max-height: 73vh;
  max-width: 90vw;
  #tooltip {
  opacity: 1 !important;
  position: absolute;
  }

  .ReactTable {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    color: white;
    border: 0;

    .rt-table {
      color: white;
      flex: none;

      .rt-thead {
        background-color: #071C36;
        padding: 0 20px;

        .rt-tr {
          //header row
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          .rt-th {
            //header cell
            display: flex;
            justify-content: left;
            padding: 0 10px;
          }

          .alignStart {
            justify-content: flex-start;
          }
          .alignEnd {
            justify-content: flex-end;
          }
        }
      }

      .rt-tbody {
        overflow: visible;
        border: 0;


        .rt-tr-group {
          margin: 0 15px;
          border-bottom: solid 1px $off-white;

          .rt-tr {
            //table row
            .rt-td {
              //tabel cell
              display: flex;
              justify-content: left;
              border: none;
              padding: 0 10px;
            }

            .alignStart {
              justify-content: flex-start;
            }
            .alignEnd {
              justify-content: flex-end;
            }
            .device-table-row-actions {
              overflow: visible;
              justify-content: flex-end;
            }
          }
        }
      }
    }

    .pagination-bottom {
      height: $cell-height;
      display: flex;
      justify-content: center;

      .-pagination {
        z-index: 0;
        box-shadow: none;
        justify-content: center;
        border: none;
        // next and prevoius buttons
        .-previous,
        .-next {
          flex: 0;

          > button {
            width: 45px;
            color: $dark-indigo;
            font-size: 16px;
            border: none;

            &:hover {
              opacity: 0.6;
            }

            &:disabled {
              color: $color-main-light-gray;
              //visibility: hidden;
            }
          }
        }

        // remove margin and padding in order to get spave for the design
        .-pageInfo {
          color: $dark;

          .-pageJump {
            > input {
              color: $dark;
              background-color:white;
              border: none;
              width: 40px;
            }
          }
        }

        // remove flex to get elements close to each other
        .-center {
          flex: 0;
        }

        .-btn {
          background-color: white;
        }
      }
    }


    .rt-resizer {
      width: 2px;
      right: -1px;
      background-color: $white;
      margin: 7px 0;
    }

    .rt-noData {
      width: 100%;
      text-align: center;
    }
  }

  // asc/desc table icon override
  .ReactTable {
    .rt-thead {


      .rt-resizable-header {
        box-shadow: none;
        border: none;
        //background: linear-gradient(90deg, rgba(120, 133, 206, 1) 60%, rgba(131, 143, 214, 1) 100%);
        height: 45px;
        padding: 5px 10px;

        &:after {
          content: "";
          width: 40px;
          height: 30px;
          background: url('../../../assets/smart-table/sort.png') 0 0 no-repeat;
          float: right;
          position: relative;
          top: 8px;
          left: 15px;
          opacity: 0.5;
        }

        &:last-of-type {
          .rt-resizer {
            display: none;
          }
        }
      }
    }

    .-sort-desc {
      box-shadow: none !important;

      &:after {
        background: url("../../../assets/smart-table/sort-up.png") 0 0 no-repeat;
        left: 28px;
      }
    }

    .-sort-asc {
      box-shadow: none !important;

      &:after {
        background: url("../../../assets/smart-table/sort-down.png") 0 0 no-repeat;
        left: 28px;
      }
    }

    // checkbox
    .filter-checkbox {
      position: relative;
      top: 10px;
      left: 45%;
      height: 30px;
      width: 30px;
    }

    // fix when date range is over filter dropdown
    .css-26l3qy-menu {
      z-index: 3 !important;
    }

    // table design that overrides default table css
    .rt-thead.-header {
      font-size: 14px;
      font-weight: bold;
      height: 45px;

      .header-cell {
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          font-size: 14px;
          font-weight: 600;
        }

        // icon on the filter in the header
        .filter-img {
          height: 21px;
          width: 21px;
        }
      }
    }

    .rt-resizable-header {
      display: flex;
      align-items: center;

      .rt-resizable-header-content {
        width: 100%;
      }

      &:after {
        height: 20px;
        left: initial;
        bottom: initial;
      }
    }

    .rt-tr.-odd {
      //background-color: $second_background;
    }

    .rt-tr.-even {
      //background-color: $second_background;
    }

    // all rows
    .rt-tr-group {
      height: $cell-height;
      margin: 8px 0;

      .rt-tr {
        color: $dark;
        font-size: 14px;

        .rt-td {
          align-items: center;
          display: flex;
        }
      }
    }

    .rt-thead.-filters {
      border: none;
    }

    // position filters verttical middle
    .date-range-wrapper,
    .singleselect,
    .filter-checkbox,
    .rt-th > input {
      transform: translate(0px, 40px);
      margin-bottom: 50px;
    }

    .number-filter {
      transform: translate(0px, 13px);
      margin-bottom: 23px; // pushes the filter row down and give space for filters
    }

    .rt-th > input,
    .singleselect {
      width: 90% !important;
    }

    .-pagination {

    }

    .-totalPages {
      width: 20px !important;
    }

    .-pageSizeOptions {
      display: none;
    }
  }

  .button-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .label {
      width: 104px;
      height: 45px;
      border-radius: 6px;
      background-color: #00b8ec;
      text-align: center;
      align-self: center;
      color: white;
    }

    .separator {
      height: 20px;
      width: 2px;
      margin: 10px;
      background-color: $silver;
    }

    .menu-separator {
      height: 20px;
      width: 2px;
      margin: 3px;
      background-color: $silver;
    }

    // action icon
    .action-icon {
      max-width: 55px;
      max-height: 55px;
      cursor: pointer;
      padding: 10px;
      background-clip: padding-box;
      border-radius: 50%;

    .meow-icon{
      color: #48648B;
    }


      &:hover {
        background-color: $silver;
      }
    }
  }

}

.smart-table-top {
  position: absolute;
  top: 20px;
  right: 175px;
}


