.topbar-logo, .sidebar-logo {
  cursor: default;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  > h2 {
    font-size: 19px;
    letter-spacing: 5px;
    color: black;

    span {
      letter-spacing: 5px;
      color: #e84545;
    }
  }
}

.card-container {
  border-radius: 5px;
  margin: 5px;
  background-color: #113e60;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms;

}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}
