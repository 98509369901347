.color-picker {
  width: 100%;

  .color-picker__swatch {
    padding: 5px;
    background: #fff;
    box-shadow: 0 0 0 1px rgba(0,0,0,.1);
    display: inline-block;
    cursor: pointer;
  }

  .color-picker__popover {
    position: absolute;
    z-index: 2;
  }

  .color-picker__cover {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
}