@import "../colors";

.visibility-view-container {
  display: flex;
  height: 100%;
  flex: 1;
  flex-direction: column;
  justify-content: center;

  .card-container {
    border-radius: 24px;
    box-shadow: 0 2px 4px 0 rgba(85, 85, 85, 0.5);
    background-color: rgba(140, 135, 135, 0.12);
    padding: 2%;

    .inner-card {
      border-radius: 24px;
      box-shadow: 0 2px 4px 0 rgba(73, 72, 72, 0.5);
      background-color: #345d7d;
      padding-bottom: 24px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

    }
  }
}

.vv-query-container-wrapper{
  display: flex;

}

.vv-query-container {
  //border-radius: 5px;
  background-color: $dark-indigo;
  display: flex;
  box-shadow: 0 2px 7px 0 rgba(133, 133, 133, 0.55);
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 5px 0;
  position: relative;
  line-height: 1;


  .flex-time-fro-to{
    flex-grow: 2;
  }
  .flex-time-window{
    flex-grow: 1;
  }
  .flex-query{
    flex-grow: 3;
  }
  .flex-validate{
    flex-grow: 1;
  }
  .vv-query-section {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 15px;
    flex-wrap: wrap;

    .vv-input-wrapper {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      .analyse-button-on-query {
        margin: 35px 0 0 20px;
      }
      .vv-input-label-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .vv-input-label {
        display: flex;
        align-items: center;
        font-size: 18px;
        margin: 5px;
        font-weight: bold;
        color: $white;
      }

      .vv-input-container {
        min-height: 45px;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 10px;

        .vv-input {
          position: relative;
          //background-color: $white;
          display: flex;
          align-items: center;
          border-radius: 5px;
          border: solid 1px $light-blue-grey;
          //background-color: $white;
          background-color: $white;
          height: 45px;
          margin: 5px;

          input {
            width: 100px;
            font-size: 16px;
            text-align: left;
          }

          .vv-time-units-input {
            background-color: transparent;
            border: none;
            text-align: center;
            color: $dark-indigo;

            width: 50px;
          }

          .vv-time-units-input-text {
            font-size: 16px;
            color: $dark-indigo;
            margin-right: 10px;
          }
        }
      }
    }

    .vv-pickers-separator {
      width: 14px;
      margin: 0 5px;
    }

    @media screen and (max-width: 1550px) {
      flex-direction: column;
      justify-content: center;
      .vv-pickers-separator {
        display: none;
      }
      .vv-pickers-from-wrapper, .vv-pickers-to-wrapper {

        .vv-pickers-from,
        .vv-pickers-to {
          justify-content: flex-start;

        }

        .vv-picker-from, .vv-picker-to, .vv-time-from, .vv-time-to {
          margin-right: 30px;

          > div:first-of-type.calendar-icon, div:first-of-type.time-icon {
            top: 6px;
          }

          input {
            text-align: center;
            position: relative;
            left: -8px
          }
        }
      }
    }

  }

}

.top-section-user {
  display: flex;
  justify-content: space-between;
  padding-bottom: 0;
  margin-right: 6px;
  margin-bottom: 10px;
}

.filtersActive {
  background-color: #ff0e3b;
  width: 8px;
  height: 8px;
  border-radius: 15px;
  right: 13px;
  top: 13px;
  position: absolute;
}

.define-time-segments {
  display: flex;
  flex-direction: column;
  margin: 0 5px 5px 5px;
  position: relative;

  .top-section {
    display: flex;
    justify-content: space-between;
    margin: 5px 0;

    .define-time-text {
      font-size: 20px;
      font-weight: bold;
      color: $dark-indigo;
      margin-left: 5px;

    }

    .filter-icon-container {
      display: flex;
      border-radius: 5px;
      align-items: center;
      background-color: $dark-indigo;
      padding: 5px 15px;
      cursor: pointer;

      .filter-icon {
        color: $white;
        margin: 0 5px;
        width: 15px;

        &.active {
          fill: red;
        }
      }

      .filter-title {
        font-size: 18px;
        font-weight: bold;
        color: $white;
      }
    }


  }

}

.vv-analyze-time {

  display: flex;
  flex-direction: column;
  justify-content: start;

  flex: 7;
  height: 100%;

  .inner-card {
    height: 130px;
    padding-bottom: 15px !important;
  }

  @media screen and (max-width: 1766px) {
    .vv-line-between-pickers {
      position: absolute;
      right: 20px;
      top: 80px
    }

  }
}

.vv-analyze-button {
  width: 182px;
  height: 45px;
  margin-left : 5px;
  font-size: 18px;
  font-weight: bold;
}

.validation-error {
  color: $coral;
  font-size: 15px;
  font-weight: bold;
  margin: 5px;
}

.vv-analyze-text {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-self: flex-start;

  font-size: 24px;
  font-weight: 400;
  color: #ffffff;
  padding-left: 8px;
  margin-bottom: 4px;
}


.vv-pickers-from,
.vv-pickers-to {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 380px;
}


.vv-picker-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.vv-text-from,
.vv-text-to {
  min-width: 40px;
  font-size: 17px;
  letter-spacing: 0.14px;
  color: #ffffff;
}

.vv-line-between-pickers {
  width: 23px;
  height: 1px;
  border: solid 0.1px #ffffff;
  margin: 0 2%;
}

.vv-time-units {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  flex: 2;
  margin: 0 24px;

  .inner-card {
    height: 130px;
  }
}

.vv-time-units-text {
  font-size: 24px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 1%;
}

.vv-time-units-input-container {
  display: flex;
  flex-direction: row;
  justify-content: center;

}


.vv-type-select {
  flex: 3;

  .inner-card {
    height: 130px;
  }

  .vv-type-select-container {
    padding: 0 16px;
    display: flex;
    flex-direction: column;
  }
}

.no-segment {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.graphs-wrapper {
  background-color: $white;
  border-radius: 5px;
  margin: 5px;
  box-shadow: 0 2px 7px 0 rgba(133, 133, 133, 0.55);
  display: flex;
  flex-grow: 5;
  flex-direction: column;
  min-height: 300px;
  justify-content: center;
  padding: 20px !important;
}

.recharts-surface {
  width: 100% !important;
}

.recharts-wrapper {
  width: 100% !important;
}

.crossed-circle {
  width: 81px;
  height: 81px;
  background-image: url('../../assets/layout/circle-crossed-out.png') !important;
  background-size: 100% 100%;
}



.background-process-logo {
  width: 81px;
  height: 81px;
  background-image: url('../../assets/layout/process-icon.png') !important;
  background-size: 100% 100%;
}
.vv-no-segment-text {
  height: 29px;
  font-size: 24px;
  letter-spacing: 0.14px;
  color: #8391a7;
  display: flex;
  justify-content: center;
}

.graphs-title-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}


.graph {

  &.visibility-view-graph {

    margin: 0;

    > div {
      height: auto !important;
      //width: 100% !important;

      .rv-xy-plot {
        height: 310px !important;
      }


      > div:nth-of-type(2) {
        height: 310px !important;
      }
    }

  }
}

.table_placeholder {
  flex: 1;
  min-height: 250px;

  align-items: center;
  justify-content: space-between;
  position: relative;
  margin: 1%;
}


.graph-text-over {
  margin: 0 1%;
  margin-top: 3%;
  font-size: 12px;
}

.graph-loading-container {
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
}

.graph-loading {
  width: 200px;
  height: 96px;
  background-image: url('../../assets/animations/LoadingAnimated.png') !important;
  background-size: 100% 100%;
}

.graph-loading-text {
  margin-top: 15px;
  font-weight: 500;
  font-size: 18px;
  color: white;

}

