@import '../../../../scss/colors';
@import '../../../../scss/fonts';

.update-threshold {
    width: 100%;
    min-height: 110px;
    height: 100%;
    opacity: 1;
    border-radius: 5px;
    background-color: $dark-indigo;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-content: center;
    margin: 0px 0 0 15px;

}

.form-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;

    .update-treshold-title {
        width: 110px;
        height: 16px;
        font-family: $font;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.14;
        letter-spacing: 0.31px;
        color: $white;

    }

    .input-container {
        display: flex;

        .threshold-field-type {
            align-self: flex-end;
            width: 206px;
            height: 45px;
        }

        .threshold-field-value {
            width: 350px;
            height: 45px;
            display: flex;
            flex-direction: row;

            input {
                font-size: 15px;
                width: 150px;
                height: 45px;
                border-radius: 5px;
                border: solid 1px $light-blue-grey;
                background-color: $white;

            }

        }
    }
}


.update-threshold-groups {
    display: flex;
    justify-content: space-between;
    width: 315px;

    .button-update {
        width: 150px;
        height: 45px;
        border-radius: 5px;
    }

    .cancel {
        box-shadow: 0 5px 10px 0 #00000019;
        border: solid 1px $light-blue-grey;
        color: $light-blue-grey;
        background-color: transparent;
    }

    .save {
        box-shadow: 0 5px 10px 0 #00000019;
        border: solid 1px $bright-sky-blue;
        background-color: $bright-sky-blue;
        color: $white;

    }
}