@import '../../../../scss/fonts';
@import '../../../../scss/colors';

.threath-action {
    display: flex;
    align-items: center;
    padding: 5px;

    button {
        background: transparent;
        border: 0;
    }

    .action-icon {
        padding: 10px;
    }
}

.separator {
    height: 20px;
    width: 2px;
    background-color: $silver;
}