@import "../../../scss/settings/variable";

.smart-form {
  padding: 20px 45px 35px 45px;
  .card__title {
    h4 {
      text-align: center;
      color: $color-main-blue;
      //margin-top: 26px;
    }
  }
  form {
    .form-input-fields {
      position: relative;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      font-size: 15px;
      .form__form-group {
        width: 284px;
        .form__form-group-field {
          input {
            max-width: 310px;
            width: 100%;
            height: 34px;
            object-fit: contain;
            border-radius: 4px;
            background-color: #f2f8fd;
            color: $color-main-gray;
            border: solid 1px #eaeef5;
            &:focus {
              background-color: $color-main-white;
            }
          }
          .form__form-group-input {
            width: 150px;
          }
        }
      }
    }
    .sf-buttons {
      padding-top: 30px;
      button {
        color: white;
        font-size: 16px;
        border: 0;
        width: 92px;
        height: 31px;
        border-radius: 17px;
        background-color: $color-main-blue;
        display: flex;
        align-items: center;
        justify-content: center;

        &[type="submit"] {
          background-color: $color-main-blue;
          color: white;
        }
        &[type="button"] {
          background-color: $color-main-light-gray;
          color: $color-main-blue;
        }
      }
    }
  }
  .form__form-group {
    .react-select__control {
      max-width: 310px;
      width: 100%;
      height: 31px;
      object-fit: contain;
      border-radius: 4px;
      background-color: #f2f8fd;
      color: $color-main-gray;
      border: solid 1px #eaeef5;
      &:focus {
        background-color: $color-main-white;
      }
      .react-select__value-container {
        padding: 0 8px;
        .css-w8afj7-Input {
          margin: 0;
          padding: 0;
        }
      }
    }
    &.multiselect {
      .react-select__control {
        height: unset;
      }
    }
  }
}
