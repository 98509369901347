main {
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 13px;

  @include themify($themes) {
    //color: themed("colorText");
  }
}

* {
  box-sizing: inherit;
}

ul,
ol {
  padding-left: 15px;
  margin-bottom: 0;
}

a {
  color: $color-main-white;
  transition: all 0.3s;

  &:hover {
    text-decoration: none;
    color: $color-main-white;
  }
}

img {
  width: 100%;
}

textarea:focus,
input:focus {
  outline: none;
}
