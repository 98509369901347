@import "../../../../scss/colors";
@import "../../../../scss/fonts";

.box-ip-core {
  align-items: center;
  display: flex;
  margin: 1.5%;
  width: 112px;
  height: 20px;
}

.box-mac {
  border-radius: 4px;
  text-align: center;
  background-color: $bright-sky-blue;
  justify-content: center;

  p {
    text-align: center;
    height: 11px;
    font-family: $font;
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.31px;
    color: $white;
    margin: 0;
  }
}

.box-ip {
  border-radius: 4px;
  text-align: center;
  background-color: $grey-bleu;
  justify-content: center;

  p {
    text-align: center;
    height: 11px;
    font-family: $font;
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.31px;
    color: $white;
    margin: 0;
  }
}

.box-organization {
  border-radius: 4px;
  text-align: center;
  background-color: $dark-indigo;
  justify-content: center;

  p {
    text-align: center;
    height: 14px;
    font-family: $font;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.31px;
    color: $white;
    margin: 0;
  }
}

.box-aws {
  border-radius: 4px;
  text-align: center;
  background-color: $light-indigo;
  justify-content: center;

  p {
    text-align: center;
    height: 12px;
    font-family: $font;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.31px;
    color: $white;
    margin: 0;
  }
}

.ip-tooltip>div>div {
  min-width: max-content;
  opacity: 0.95;
  padding: 6px;
  border-radius: 4px;
}
