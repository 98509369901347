.reason {
    padding: 5px;
    width: 250px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .close-button { 
        align-self: flex-end;
    }
    .style-button { 
        height: 20px;
        width: 100%;
    }
    .MuiFormControl-root {
        width: 100%;
        border: 0;
        margin: 5px 0 5px 0;
    }
}