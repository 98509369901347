@import "../../../../scss/colors";

.style-button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 55px;
  border-radius: 4px;
  background-color: $bright-sky-blue;

  height: inherit;

  cursor: pointer;

}
