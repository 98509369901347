
.modal {
  display: flex !important;
  flex-direction: column;
  align-items: center;

  .modal-dialog {
    max-width: 1000px;
    min-width: 520px;
    margin: 1.75rem auto;
  }
}


@media (min-width: 576px) {
  .modal-dialog {
    max-width: 750px;
    min-width: 520px;
    margin: 1.75rem auto;
  }
}

.standard-modal {
  .modal__header {
    display: flex;
    justify-content: space-between;
    padding: 12px 15px;
    background-color: rgba(38, 55, 76, 1);
    color: white;
  }

  .standard-modal-body {
    display: flex;
    flex-direction: column;

    .pause-modal {
      text-align: center;

      .description {
        padding: 15px;
      }

      .pause-buttons {
        display: flex;
        justify-content: space-evenly;
      }
    }
  }
}
