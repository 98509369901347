@import '../../../../scss/settings/variable';




.react-select-redux-forms{
  height: 45px;
  min-width: 100%;
  max-width: 100%;
  font-size: 15px;
}


.react-select__control {
  height: 45px;
  //border-radius: 0 !important;
  transition: all 0.3s;
  overflow: auto;
  @include themify($themes) {
    border: 1px solid themed("colorFieldsBorder");
    background-color: themed("colorBackground");
  }

  &.react-select__control--is-focused,
  &:hover {
    box-shadow: none;
    background: transparent;
  }
}

/* Hide the scroll bar to chrome users */
.react-select__control::-webkit-scrollbar {
  display: none;
}

/* Hide the scroll bar to IE + Edge, FireFox users */
.react-select__control::-webkit-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.react-select__input {
  height: 30px;
}

.react-select__indicator-separator {
  display: none;
}

.react-select__dropdown-indicator,
.react-select__clear-indicator {
  cursor: pointer;

  @include themify($themes) {
    color: themed("colorIcon");
  }

  svg {
    height: 16px;
    width: 16px;
  }
}

.react-select-redux-forms{
  .react-select__multi-value {

    .react-select__multi-value__label {
      @include themify($themes) {
        color: themed("colorText");
      }
    }
  }

  .react-select__multi-value__remove {
    opacity: 0.8;
    transition: 0.3s;
    cursor: pointer;

    @include themify($themes) {
      color: themed("colorText");
    }
  }

  .react-select__multi-value__label,
  .react-select__multi-value__remove {
    @include themify($themes) {
      background: themed("colorBackground");
    }
  }

  .react-select__single-value {
    @include themify($themes) {
      color: themed("colorText");
    }
  }
}

.react-select__menu {
  box-shadow: none !important;
  margin-top: 6px;
  margin-bottom: 6px;
}

.react-select__menu-list {
  top: calc(100% + 1px);
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  animation: open 0.3s ease-in-out;
  overflow: hidden;

  @include themify($themes) {
    background: themed("colorBackground");
    border: 1px solid themed("colorFieldsBorder");
  }
}

@keyframes open {
  0% {
    max-height: 0;
  }
  100% {
    max-height: 200px;
  }
}

.react-select__option {
  transition: all 0.3s;
  border-radius: 0;
  display: flex;
  cursor: pointer;
  padding: 8px 10px;

  @include themify($themes) {
    background: themed("colorBackground");
    color: themed("colorText");
  }

  &.react-select__option--is-focused {
    @include themify($themes) {
      background: themed("colorHover");
    }
  }
}

.react-select__color {
  display: block;
  border-radius: 50%;
  height: 10px;
  width: 10px;
  margin: auto 0 auto 5px;
}

.form__select-color {
  display: flex;
  flex-wrap: wrap;
  max-width: 84px;
  margin-right: 40px;
}


.react-select-redux-forms.react-select-underline {
  .react-select__control {
    background-color: $white;
    border: 1px solid $light-blue-grey;
    border-radius: 5px;

    .react-select__value-container {

      .react-select__single-value {
        font-size: 14px;
        margin: 0 4px;
        color: $dark-indigo;
      }

      .react-select__placeholder {
        color: #9aaebe;
      }

      > div {
        > .react-select__input {
          > input {
            font-size: 14px; // there are inline styles on the input element that need to be overridden
            color: $dark-indigo !important; // there are inline styles on the input element that need to be overridden
            position: relative;
            top: 4px
          }
        }
      }

      .react-select__multi-value {
        background-color: $bright-sky-blue;
        border-radius: 3px;
        overflow: auto;

        .react-select__multi-value__label {
          color: #fff;
          font-size: 14px;
          //margin: 0 4px;
          margin: 0 4px;
        }

        .react-select__multi-value__remove {
          //padding: 0 6px;

          > svg {
            fill: $white;
          }

          &:hover {
            background-color: rgba(110, 98, 103, 0.5);
            opacity: 1;
          }
        }
      }
    }

    .react-select__clear-indicator, .react-select__dropdown-indicator {
      svg {
        opacity: 0.6;
        fill: $dark-indigo;
      }

      &:hover {
        svg {
          opacity: 1;
        }
      }
    }
  }

  > .react-select__menu {
    background-color: $white;
    mso-build-order: 1px solid $silver-two;
    border-radius: 5px;
    border: 1px solid $silver-two;
    //margin: 2px 8px;
    box-sizing: border-box;

    > .react-select__menu-list {
      .react-select__option {
        //height: 31px;
        border-radius: 3px;
        font-size: 14px;
        background-color: $white;
        color: $dark-indigo;
        display: flex;
        align-items: center;
        margin: 5px 0;

        &:hover {
          color: $white;
          background-color: $bright-sky-blue;
        }
      }
    }
  }

  &.react-select-creatable {
    .react-select__dropdown-indicator {
      display: none;
    }
  }
}
