@import '../../../../scss/colors';
@import '../../../../scss/fonts';

.integration-container{
    box-shadow: 0 10px 26px -10px #00000059;
    background-color: $white;
    min-width: 967px;
    min-height: 600px;

    overflow: overlay;
    .integration-boxes{
        display: flex;
        min-height: inherit;
        .left-box{
            width: 50%;
            flex-direction: row;
            min-height: inherit;
            padding: 20px;
            .integration-header{
                margin-bottom: 15px;
                font-family: $font;
                font-size: 18px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 0.89;
                letter-spacing: 0.4px;
                color: $dark;
            }

            .integration-description{
                margin-bottom: 15px;
                font-family: $font;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.38;
                letter-spacing: 0.3px;
                color: $dark;
            }
        }

        .right-box{
            width: 50%;
            display: flex;
            flex-direction: column;
            min-height: inherit;
            .integration-icon{ 
                width: 25%;
                align-self: flex-end;
                margin: 20px 20px 0 0;
            }
        }
    }

    .integration-footer{
        display: flex;
        flex-direction: column;
        margin: 10px;
        .integration-submit{
            min-width: 240px;
            align-self: center;
        }
        .integration-cancel{
            cursor: pointer;
            text-decoration: underline; 
            align-self: center;
        }
        .integration-cancel:hover{ 
            color: $dark;
        }
    }
   
}