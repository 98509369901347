.ip-enrichment-dialog-wrapper{
  width: 500px;
  height: auto;
  font-size: 10px;
  overflow: visible;

  .ip-enrichment-dialog-title {
    padding-bottom: 20px;
  };

  .ip-enrichment-dialog-save-button {
    text-align: center;
    width: 150px;
    height: 40px;
    border-radius: 5px;
    text-align: center;
    font-weight : bold;
    font-size: 16px;

  }

}
