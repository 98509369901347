@import "../../../../scss/colors";


.form-field {
  .MuiFormLabel-root {
    //font-size: 16px;
    color: $cool-grey;

    .MuiFormLabel-asterisk {

    }
  }

  .MuiInput-underline {
    border-bottom: 1px solid $dark;

  }

  .MuiInput-underline:after {
    border-bottom-color: $dark;
  }

  .MuiInput-underline.Mui-error {
    border-bottom: 1px solid $coral;

  }

  .MuiInput-underline.Mui-error:after {
    border-bottom: 1px solid $coral;
  }

  .MuiFormLabel-root.Mui-focused {
    color: $cool-grey;
  }

  .MuiInput-root {
    .MuiInput-input {
      //font-size: 16px;
      color: $dark;


    }
  }

  .MuiFormLabel-root.Mui-error {
    //font-size: 16px;
    color: $coral;

    .MuiFormLabel-asterisk {
      color: $coral;
    }
  }

  .MuiFormHelperText-root.Mui-error {
    font-size: 11px;
    position: absolute;
    color: $coral;
    margin-top: 50px;
  }


}