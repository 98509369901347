#root,
.wrapper,
main {
    width: 100%;
    height: 100%;
}

.error-text-input {
    width: 100%;
    text-align: right;
    color: red;
}