.set-password-form {
    .form-field {
        width: 80%;
    }

    .email-input {
        font-size: 12px;
        width: 80%;
        margin-top: 20px
    }

    .email-label {
        width: 100%;
    }

    .instructions {
        font-size: 14px;
        margin: 10px 0 10px 0;
    }
}

div.field-restriction{
    color: red;
    font-weight: bold;
}

