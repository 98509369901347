.login-form-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.login-form {
  min-width: 559px;
  min-height: 559px;
  border-radius: 54px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
}


.login-logo {
  width: 170px;
  height: 106px;
  background-image: url('../../assets/login/logo-vertical.png') !important;
  background-size: 100% 100%;
  margin: 65px 0;
}

.login-username {
  margin-bottom: 40px !important;
}

.login-password {
  margin-bottom: 82px !important;
}


.login-username,
.login-password {
  width: 279px !important;

  // LABEL
  .MuiFormLabel-root {
    font-size: 17px;
    font-weight: 500;
    letter-spacing: -0.17px;
    color: #000000;
  }

  .MuiInput-root {
    .MuiInput-input {
      font-size: 20px;
      font-weight: normal;
      letter-spacing: -0.24px;
      color: #00bbf1;
    }
  }
}

div.sign-up{
}

div.sign-up > a{
    color: #007bff;
}

div.login-captcha{
  margin-top: 10px;
}

.forgot-password{
  color: #007bff !important;
}

.forgot-password:hover{
  color: #007bff !important;
}

.forgot-password-error{
  color: #f05f5a !important;
  text-decoration: underline;
}

.forgot-password-error:hover{
  color: #f05f5a !important;
  text-decoration: underline;
}
