@import '../../../../scss/colors';
@import '../../../../scss/fonts.scss';

.copied {
    font-family: $font;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: $bright-sky-blue;
    margin-top: 5px;

}

.copy-button {}

.copy-button:hover {
    path { fill: $bright-sky-blue; }

}