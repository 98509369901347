@import '../../../../scss/colors';
@import '../../../../scss/fonts';

.input-wrapper {
    display: flex;
    flex-direction: column;
    padding: 8px;

    .name {
        font-family: $font;
        font-size: 12px;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.89;
        letter-spacing: 0.4px;
        color: $white;
        margin: 5px;
        display: flex;
        flex-direction: column;

        .name-tooltip {
            height: 11px;
            display: flex;
            align-items: center;

            .tooltip-icon {
                margin-bottom: 6px;
            }
        }

    }

    .name-empty {
        font-family: $font;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.89;
        letter-spacing: 0.4px;
        color: transparent;
        margin: 5px;
        display: flex;
        flex-direction: column;
        z-index: -12;

    }

    .input {
        height: 45px;
        border-radius: 5px;
        border: solid 1px var(--light-blue-grey);
        background-color: $white;
        margin: 5px;
        font-size: 14px;
        //width: --webkit-fill-available;

    }

    .link {
        height: 45px;
        border-radius: 5px;
        border: solid 1px var(--light-blue-grey);
        background-color: transparent;
        margin-top: 5px;
        font-size: 14px;
        //width: --webkit-fill-available;
    }

    span {
        font-size: 12px;
        display: flex;
        justify-content: flex-end;
    }

    .wrapper-time-input {
        display: flex;
        align-items: center;
        input {
            height: 45px;
            border-radius: 5px;
            border: solid 1px var(--light-blue-grey);
            background-color: $white;
            margin: 5px;
            font-size: 12px;
            //width: -webkit-fill-available;
        }
    }

    .input-wrapper-error {
        color: red;
    }
}
