.line3 {
    margin: 40px 0 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .top-container {
        display: flex;
        width: 60%;
        justify-content: space-between;
    }

    .more-options-section {
        padding-right: 12px;
        width: 60%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 8px;
    }

    .src-enrichment, .dst-enrichment, .more-options {
        padding: 0 5px 0 5px;
    }

    .checkboxes {
        width: 30%;
        font-size: 12px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }

    .analyze{
        width: 45%;
        padding-right: 7px;
        padding-left: 7px;
        .analyze-button {
            width: 100%;
            height: 40px;
            color: white;
        }
        .analyze-loader {
            width: 100%;
            height: 40px;
            display: flex;
            align-self: center;
            margin: auto;
        }
    }

    .copy-and-export {
        width: 55%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    .bottom-container {
        display: flex;
        width: 40%;
    }

}


