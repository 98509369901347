@import "../../../../scss/colors";

.notifications {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  margin: 5px;
  background-color: $white;
  box-shadow: 0 2px 7px 0 rgba(133, 133, 133, 0.55);
  width: 50%;
  height: 85%;
  .title {
    background-color: $dark-indigo;
    font-size: 16px;
    border-radius: 5px 5px 0 0;
    padding: 5px 20px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .title-and-icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .general-box-icon {
    height: 20px;
    width: 20px;
    margin: 0 8px;
  }
  .threats-icon {
    height: 20px;
    width: 22px;
    margin: 0 6px 0 3px;
  }

  // THREAT NOTIFICATIONS
  .threat-notifications, .insights-notifications , .custom-alerts-notifications{
    width: 100%;
    overflow: auto;
    height: 100%;
  }
}
