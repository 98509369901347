@import '../../../../../scss/colors';

.export-button {
  height: 18px;
  width: 18px;
  cursor: pointer;
}

.export-button:hover {
  path { fill: $bright-sky-blue; }
}