@import '../../../../../scss/fonts';
@import '../../../../../scss/colors';

.line-meta-wrapper {
    display: flex;
    flex-direction: column;

    .header {

        font-family: $font;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.14;
        letter-spacing: 0.31px;
        color: $cool-grey;
        margin: 0 25px 10px 0
    }

    .bottom {
        display: flex;
        align-items: center;
        margin: 0 25px 0 0;

        .values {

            font-family: $font;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.14;
            letter-spacing: 0.31px;
            color: $dark;

        }

        .extra {
            margin: 0 0 0 20px;
        }

        .extra-plus {
            color: $coral;
        }

        .extra-minus {
            color: $orange-nice;
        }

    }

}
