@import '../../../../scss/colors';
@import '../../../../scss/fonts';

.integration-box {
    width: 370px;
    height: 290px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    margin: 10px 10px 10px 10px;
    padding: 22px;
    border-radius: 5px;
    box-shadow: 0 2px 7px 0 #8585858c;
    background-color: $white;
}


.integration-box-header {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.integration-box-logo {
    min-width: 80px;
    height: 72px;
    object-fit: contain;
    align-self: center;
}

.integration-box-title {
    height: 16px;
    font-family: $font;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.89;
    letter-spacing: 0.4px;
    color: $dark;
}

.integration-box-description {
    height: 44px;
    font-family: $font;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: 0.3px;
    color: $dark;
}

.integration-box-button {
    height: 20%;
    align-items: center;
}

.integration-page-header {
    margin: 24px 0 0 50px;
    height: 20px;
    font-family: $font;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.44px;
    color: $dark-indigo;

}

.integration-box-button-button {
    margin-left: 10px;
    align-self: center;

}

.integration-box-buttons {
    display: flex;
    justify-content: center;

}

.add-integration {
    align-self: center;
}
