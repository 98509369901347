@import '../../../../scss/colors';
@import '../../../../scss/fonts';

.filter-alertes {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .filter-title {
        font-family: $font;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 0.44px;
        color: $dark-indigo;
    }

    .MuiButton-label {

        font-family: $font;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 0.44px;
        color: $bright-sky-blue;
    }
}