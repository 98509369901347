@import "../../../scss/colors";

.graphbox {
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  margin: 5px;
  background-color: $white;
  box-shadow: 0 2px 7px 0 rgba(133, 133, 133, 0.55);
  max-height: 30.4%;
  max-width: 45em;

  .title {
    background-color: $dark-indigo;
    font-size: 16px;
    border-radius: 5px 5px 0 0;
    padding: 5px 20px;
    font-weight: bold;
  }

  .graph {
    padding-top: 10px;

    .graph-container {
      .rv-xy-plot__axis__tick__text {
        font-size: 11px;
        line-height: 1.33;
        color: $dark-indigo;
      }

    }
  }
}
