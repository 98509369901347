@import "../../scss/colors";

.management-container {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	margin: 5px;
}

.management-header {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.device-management-tabs-menu {
	border-radius: 2px;
	color: $dark-indigo;
	padding: 5px;
}

.alerts-management-tabs-menu {
	padding: 10px 0 10px 30px;
	border-radius: 2px;
	color: $dark-indigo;
	width: 50%;
}

.tab-seperator {
	margin: 16px 16px;
}

.device-management-tab {
	// border-bottom: 1px solid #071c36 !important;
}

.device-management-tab:hover {
	background: $silver-two;
}

.device-management-tab > span {
	text-transform: none;
	font-size: 16px;
	color: #071c36;
	font-weight: bold;
}

.device-management-tab:hover > span {
	text-transform: none;
	font-size: 16px;
	color: #071c36;
	font-weight: bold;
}

.device-management-tab.Mui-selected > span {
	text-transform: none;
	font-size: 16px;
	color: $bright-sky-blue;
	font-weight: bold;
}

.public-device-line-break {
	margin-top: 10px;
}

.add-device-or-collector {
	display: flex;
	justify-content: flex-end;
	column-gap: 12px;
}

.add-collector-device {
	display: flex;
	flex-direction: row;
}

.modal-form {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.edit-device-modal {
	width: 100%;

	display: flex;
	flex-direction: column;
	justify-content: center;
	font-size: 17px;

	.modal-header {
		background-color: $dark;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 20px;

		.header-image {
			width: 130px;
		}

		.title {
			color: $white;
			font-size: 34px;
			font-weight: bold;
		}

		.close-button {
			position: absolute;
			top: 20px;
			right: 20px;
		}
	}

	.rdt-input {
		width: 100%;
		color: $darker-indigo;
		font-size: 16px;
		border: none;
		border-bottom: 1px solid $dark-indigo;
		text-align: left;
	}

	.rdtPicker {
		left: 0 !important;
	}
}

.edit-device-input {
	margin-bottom: 10px;
	padding: 10px;
	font-size: 16px;
	border: none;
	border-bottom: 1px solid darkgrey;
}

.edit-device-button {
	width: 96px;
	height: 40px;
	border-radius: 6px;
	background-color: #00b8ec;
	text-align: center;
	align-self: center;
	color: white;
	cursor: pointer;
	border: none;
	font-size: 15px;
	margin-bottom: 5%;
}

.modal-login-text-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}

.modal-login-text {
	font-size: 20px;
	color: $dark;
}

.modal-edit-body {
	padding: 0 40px;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
}

.modal-login-button-wrapper {
	display: flex;
	flex-direction: row;
	margin: 0 0 60px 0;
	width: 100%;
	justify-content: space-evenly;
}

.modal-form-container {
	margin: 60px;
	width: 240px;
	display: flex;
	flex-direction: column;
}
.modal-form-user-container {
	margin: 30px;
	width: 90%;
	display: flex;
	flex-direction: column;
}
.modal-form-public-cloud-container {
	margin: 60px;
	width: 240px;
	display: flex;
	flex-direction: column;
}

.modal-form-container-config {
	width: 80%;
	max-width: 80%;
	display: flex;
	flex-direction: column;
}

.modal-login-container {
	display: flex;
	flex-direction: column;

	width: 100%;
	height: 100%;
}

.table-add-button {
	font-size: 14px;
	font-weight: bold;
	min-width: 125px;
	height: 38px;
}

// publicCloud Css POC.
.modal-form-public-cloud {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 800px;
}
.modal-edit-body-public-cloud {
	padding: 0 50px;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	margin-top: 10px;
	margin-bottom: 10px;
	font-weight: bold;
	font-size: 16px;
	width: 100%;
}
.selected-image {
	cursor: pointer;
	box-shadow: 0 4px 30px -10px #7f8792;
	border-radius: 5px;
	//border: solid 0.9px #48648b;
	border: solid 0.9px #48648b;
	padding: 7px;
}
.not-selected-image {
	cursor: pointer;
	padding: 7px;
}
.step-title {
	margin-top: 20px !important;
}
.table-add-button-with-margin {
	margin-right: 25px !important;
}

// Modal autoOnBoarding
.modal-auto-onboarding-text {
	font-weight: 100;
}
.modal-auto-onboarding-find-button {
	margin-top: 15px !important;
	width: 200px;
	height: 41px;
	display: block !important;
	margin-left: auto !important;
	margin-right: auto !important;
}

.modal-auto-onboarding-form-section {
	margin-top: 10px !important;
}
.modal-auto-onboarding-form-loading-animated {
	width: 120px;
	height: 50px;
	display: block;
	margin-left: auto;
	margin-right: auto;
}
.modal-auto-onboarding-table {
	margin-bottom: 20px !important;
	margin-top: 25px;
	width: 50%;
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.modal-auto-onboarding-onboard-button {
	margin-top: 15px;
	width: 200px;
	height: 50px;
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.cloud-picture {
	&:hover {
		background-color: transparent !important;
	}
}

.device-management-loader {
	height: 500px;
	display: flex;
	align-content: center;
}

.collectors-explanation-text {
	font-size: 16px;
	font-weight: 400;
	line-height: 16px;
}

.collectors-explanation {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.modal-section-title{
	font-size: 14px;
	font-weight: 700;
	color: $dark-indigo;
	margin-bottom: 14px;
}

.modal-form-user-role-container {
	margin: 0 30px 40px 30px;
	width: 90%;
	height: fit-content;
	display: flex;
	flex-direction: column;
}

.modal-form-user-role-label {
	font-size: 14px;
	font-weight: 600;
	letter-spacing: 0;
	text-align: left;
	height: 19px;
	padding-top: 10px;
	color: #455260;
	line-height: 16px;
}

.modal-form-user-role-desc {
	font-size: 12px;
	font-weight: 400;
	letter-spacing: 0;
	text-align: left;
	height: 17px;
	color: #425262;
	line-height: 19px;
	padding-top: 8px;
}
