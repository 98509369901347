@import "../../../../../scss/colors";


.activated-type-container{
  border: solid 1px $shamrock-green;
  border-radius: 4px;
  display: flex;
  padding: 6px 10px;
  width: 100px;
  align-items: center;
  justify-content: center;
}

.inactivated-type-container {
  border: 1px solid #fe4747;
  border-radius: 4px;
  display: flex;
  padding: 6px 10px;
  width: 100px;
  align-items: center;
  justify-content: center;
}

.activated-type-container,
.inactivated-type-container {
  .activated-type-image,
  .inactivated-type-image {
    img {
      width: 18px;
      height: 18px;
    }
  }

  .activated-type-image {
    img.inactivated {
      display: none;
    }
  }

  .inactivated-type-image {
    img.activated {
      display: none;
    }
  }

  .activated-type-text,
  .inactivated-type-text {
    color: #fe4747;
  }

  .activated-type-text {
    color: $shamrock-green;
  }
}

.tooltip-content {
  width: 225px;
  overflow: hidden;
  white-space: break-spaces;
  margin: 2em;
}

