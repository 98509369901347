.alert-graph-box {
    display: flex;
    justify-content: center;
    color : black; 

    
    cursor: pointer;
    
    .graph-box {
        width: 35%;
    }
    
    .graph-loading {
        width: 100px;
        height: 46px;
       // background-image: url(/static/media/LoadingAnimated.f2c58eed.png) !important;
        background-size: 100% 100%;
    }
}