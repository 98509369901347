@import '../../../../../scss/colors';

.querycontent {
    text-align: start;

    .advancelink {
        text-align: end;
    }

    h1 {
        text-align: center;
    }

}

.define-time-text {
    font-size: 20px;
    font-weight: bold;
    color: $dark-indigo;
    margin-left: 5px;

    svg {
        margin-left: 50px;
    }
}

.header-page {
    display: flex;
    justify-content: space-between;
}

.graph-view {
    margin: 5px 0px 20px 0px;
    height: 100%;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    box-shadow: 0 2px 7px 0 rgba(133, 133, 133, 0.55);
    background-color: #ffffff;
}
