@import '../../../../../scss/colors.scss';


.secret-value {
  cursor: pointer;
}

.credential-box {
  display: flex;
  flex-direction: column;
  padding: 20px;

  .title-p {
    height: 16px;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.89;
    letter-spacing: 0.4px;
    color: #2e3a48;
  }

  .title {
    text-align: center;
  }

  .credential-values {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  }

  .credential-value-header {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }

  .credential-value-value {
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
}

.documentation-access {
  display: flex;
  padding: 25px;
  .button {
    background-color: $shamrock-green;
  }
}