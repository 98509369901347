.alertes-inputs-line1 {
    display: flex;
    .alerte-name {

        width: 30%;
    }

    .alerte-type {
        width: 25%;
    }

    .alerte-threshold-type {
        width: 17%;
    }

    .alerte-threshold-value {
        width: 15%;
    }

    .alerteTimeWindow {

        width: 13%;
    }
    .alerte-name-input{
        padding-left: 10px;
        height: 100%;
        width: 100%;
        border: none;
        border-radius: 5px;
    }
}