@import "../../../../../scss/colors";

// NOTIFICATION
.threat-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 18px;
  height: 130px;
  border-radius: 0 0 5px 5px;
  border-top: 2px solid $silver;
  box-sizing: border-box;

  .MuiSvgIcon-root {
    fill: grey
  }

  .threat {
    display: flex;
    align-items: center;


    .threat-severity-time {
      display: flex;
      align-items: center;

      .threat-severity {
        width: 28px;
        margin-left: 10px;
      }

      .threat-time {
        color: $dark-indigo;
        font-size: 22px;
        margin-left: 17px;
      }
    }

    .threat-message {
      color: $dark-indigo;
      padding: 10px 0 10px 18px;
      min-width: 120px;

      .threat-title {
        font-size: 16px;
      }

      .threat-description {
        font-size: 12px;
      }
    }

    .makebold {
      font-weight: bold;
    }

    .small-screen-adaptable {
      max-width: 90px
    }
  }

  .actions{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 100%;

    .analysis-icon {
      padding-left: 4px;
    }
  }

  @media (max-width: 1650px) {
    .threat-description {
      display: none;
    }
  }


  // NEW NOTIFICATION
  &.new-notification {
    height: 0;
    padding-top: 0;
    padding-bottom: 0;
    transition: height 0.3s ease, padding-top 0.3s ease 0.5s, padding-bottom ease 0.5s, background-color ease 0.5s;
    overflow: hidden;

  }

  &.show-notification {
    height: 100px;
    padding: 10px 18px;
  }

}
