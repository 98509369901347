@import '../../../../../scss/colors';
@import '../../../../../scss/fonts';

.advance {

    .advance-button-container {
        display: flex;
        justify-content: flex-start;

        .advancelink {
            display: flex;
            justify-content: flex-end;
            height: 16px;
            font-family: $font;
            font-size: 18px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.89;
            letter-spacing: 0.4px;
            margin : 10px; 
        }

        .advancelink-open {
            color:$bright-sky-blue;
        }

        .advancelink-close {
            color: #ffffff;
        }

        .advance-open {
            color:$bright-sky-blue;
        }

        .advance-close {
            color: #ffffff;
        }

        img {
            width: 14px;
        }

    }

    .last-line-create {
        display: flex;
        flex-direction: row;

        .last-line-create-protocols {
            width: 100%;
        }
    }
}