@import '../../../../../scss/colors';
@import '../../../../../scss/fonts';

.line-content {
    display: flex;
    flex-direction: row;

    .line {
        display: flex;
        flex-direction: column;
        width: 100%;
        border-radius: 5px;
        box-shadow: 0 2px 7px 0 rgba(133, 133, 133, 0.55);
        background-color: $white;
        padding: 20px;
        margin: 5px 5px 5px 15px;

        .alerte-name {
            height: 16px;
            font-family: $font;
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.89;
            letter-spacing: 0.4px;
            color: $dark;
            text-align: left;

        }

        .wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .disable-content-line {
                display: flex;
                min-width: 300px;
            }
        }



        .line-desabled {
            position: absolute;
            display: flex;
            min-width: 93%;
            height: 9%;
        }
    }


    .line-update-threshold {
        position: absolute;
        display: flex;
        min-width: 100%;
        height: 9%;

        .line-desabled-actions {
            margin-left: 80%;
            display: flex;
            flex-direction: row;
        }
    }
}
