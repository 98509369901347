@import "../settings/variable";

$sidebar-width: 140px;
$sidebar-collapsed-width: 56px;
$sidebar-item-height: 56px;

.sidebar {
  width: $sidebar-width;
  background-color: $background-color;
  transition: transform 0.3s, width 0.3s;
  transform: translateX(calc(-100% - 20px));
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 18px;
  color: #ffffff;

  .sidebar-main {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .sidebar-logo {
      transition: all 0.3s;
      display: flex;
      height: 70px;
      justify-content: flex-start;
      align-items: center;
      padding: 0 5px;
      background-clip: padding-box;
      overflow: hidden;
      cursor: pointer;
      opacity: 0.5;

      .logo {
        width: 45px;
        margin: 5px;
      }

      .title {
        font-size: 16px;
        font-weight: bold;

      }

      &.active {
        background-color: $darker-indigo;
        opacity: 1;
      }

      &:hover {
        opacity: 1;
        background-color: $darker-indigo;
      }

    }

    .sidebar-item {
      transition: all 0.3s;
      height: $sidebar-item-height;
      cursor: pointer;
      box-sizing: content-box;
      background-clip: padding-box;
      opacity: 0.5;

      .sidebar-image {
        max-width: 20px;
        max-height: 20px;
        margin: 15px;
      }

      &:hover {
        background-color: $darker-indigo;
        opacity: 1;
      }
    }

  }

  .sidebar-bottom {
    border-top: 1px solid rgba(242, 245, 246, 0.5);

  }

  &.sidebar--collapse {
    .sidebar-logo {
      width: $sidebar-collapsed-width;
      overflow: hidden;

      .logo {

      }

      .title {
        position: absolute;
        left: $sidebar-collapsed-width;
        display: none;
      }
    }

  }


  &.sidebar--show {
    transform: translateX(0);

    .sidebar__back {
      display: block;
    }
  }
}

.sidebar__back {
  height: 100%;
  width: 100vw;
  position: absolute;
  display: none;
  border: none;
}

.sidebar__link-active {
  .sidebar__link {
    box-sizing: border-box;
    background-color: $darker-indigo;
    border-left: 4px solid $bright-sky-blue;

    .sidebar__link-icon {
      font-size: 20px;
      line-height: 13px;
      color: $color-main-blue;
      font-weight: bold;
    }

    .sidebar__link-title {
      font-weight: bold;
      opacity: 1;
    }

    .sidebar__link-image {
      opacity: 1;
    }
  }
}

.sidebar__scroll {
  width: 220px;

  & > div {
  }

  .scrollbar-track {
    &.scrollbar-track-y {
      width: 2px;
      margin-right: 3px;
    }

    &.scrollbar-track-x {
      display: none !important;
    }
  }

  .scrollbar-thumb {
    opacity: 0.3;
    width: 5px;
  }
}

.sidebar__content {
  overflow: auto;

  .sidebar__collapse-icon {

  }
}

.sidebar__block {
  overflow: hidden;
  padding: 15px 0;

  @include themify($themes) {
    border-bottom: 1px solid themed("colorBorder");
  }

  &:last-child {
    border: none;
  }
}

.sidebar__link {
  height: 56px;
  width: $sidebar-width;
  padding: 5px;
  transition: all 0.3s;
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  border: none;

  @include themify($themes) {
    color: themed("colorText");
  }

  &:hover {
    text-decoration: none;
    background-color: $darker-indigo;

    &:before {
      opacity: 1;
    }

    .sidebar__link-title {
      font-weight: bold;
      opacity: 1;
    }

    .sidebar__link-image {
      opacity: 1;
    }

    .sidebar-account-menu-button {
      opacity: 1;
    }

    .sidebar-account-icon {
      opacity: 1;
    }
  }

  .sidebar__link-title {
    margin: 0;
    position: relative;
    display: flex;
    font-size: 14px;
    color: $white;
    opacity: 0.5;
  }

  .sidebar__link-image {
    max-width: 20px;
    max-height: 20px;
    margin: 10px;
    opacity: 0.5;
  }

  .sidebar__link-icon {
    font-size: 20px;
    line-height: 13px;
    color: $color-main-blue;
    font-weight: bold;
  }

  .sidebar-account-menu-button {
    margin-left: 8px;
    margin-top: 1px;
    opacity: 0.7;
    color: $white;
    line-height: 13px;
    height: 8px;
    width: 8px;
  }

  .sidebar-account-icon {
    opacity: 0.5;
    max-width: 15px;
    max-height: 15px;
    margin: 10px;
  }
}

.sidebar__submenu {
  transition: height 0.5s 0s, padding 0.5s 0s, opacity 0.4s 0.1s;
  padding: 15px 0;

  & .sidebar__submenu {
    margin-bottom: 0;
    padding-bottom: 0;
    padding-top: 0;

    .sidebar__link {
      padding-left: 53px;

      p {
        left: 53px;
      }
    }
  }

  @include themify($themes) {
    background-color: themed("colorHover");
  }

  .sidebar__link {
    padding-left: 43px;

    &:hover {

      @include themify($themes) {
        background-color: themed("colorBackground");
      }
    }

  }
}

.sidebar__category-icon {
  position: absolute;
  right: 15px;
  font-size: 10px;
  line-height: 14px;
  opacity: 1;
  transition: opacity 0.5s 0.2s, transform 0.3s;
  color: $color-gray;
}

.sidebar__link-badge {
  width: 26px;
  height: 14px;
  background-color: $color-red;
  font-size: 8px;
  font-weight: 400;
  padding: 2px;
  margin-left: 5px;
  line-height: 9px;
  position: relative;
  text-transform: uppercase;
  border-radius: 7px;

  span {
    position: absolute;
    left: 0;
    top: 3px;
    width: 26px;
    text-align: center;
  }
}

.sidebar__wrapper--desktop {
  display: none;
}

.sidebar__category-wrap {
  &.sidebar__category-wrap--open {
    .sidebar__category-icon {
      transform: rotate(90deg);
    }
  }
}

.sidebar__category-new {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  top: -3px;
  display: block;
  margin-left: 5px;
  background: $color-red;
}

@media screen and (min-width: 576px) {
  .sidebar {
    transform: translateX(0);

    &.sidebar--no-desktop {
      transform: translateX(calc(-100% - 20px));

      &.sidebar--show {
        transform: translateX(0);
      }

      .sidebar__wrapper--mobile {
        display: block;
      }
    }

    &.sidebar--collapse {
      width: $sidebar-collapsed-width;
      overflow: visible;

      .sidebar__content {
        width: $sidebar-collapsed-width;
        overflow: visible !important;
        transition: width 0.3s;
      }

      .sidebar__submenu {
        padding: 0 0 15px 0;
        transition: 0s;
      }

      .sidebar__category-wrap {
        &:hover {
          .sidebar__category {
            width: 240px;

            @include themify($themes) {
              background: themed("colorHover");
            }

            &:before {
              opacity: 1;
            }
          }

          .sidebar__submenu-wrap {
            width: 185px;
          }
        }
      }

      .sidebar__submenu-wrap {
        position: absolute;
        left: 55px;
        width: 0;
        transition: 0.3s;
        overflow: hidden;

        .sidebar__link {
          width: 185px;
          padding-left: 15px;

          p {
            position: relative;
            left: 0;
            animation: none;
          }
        }

        .sidebar__submenu-wrap {
          position: relative;
          left: 0;

          .sidebar__link {
            padding-left: 30px;
          }
        }
      }

      .sidebar__link {
        overflow: hidden;
        width: $sidebar-collapsed-width;

        p {
          position: absolute;
          left: $sidebar-collapsed-width;
          width: 160px;
        }

        &:hover {
          width: $sidebar-width;
          background-color: $darker-indigo;
          border-radius: 0 5px 5px 0;

          .sidebar__link-title {
            font-weight: bold;
            opacity: 1;
          }

          .sidebar__link-image {
            opacity: 1;
          }
        }
      }

      .sidebar__category-icon {
        opacity: 0;
        transition: opacity 0s;
      }

      .scrollbar-track.scrollbar-track-y {
        margin-right: 188px;
      }
    }
  }

  .sidebar__wrapper--desktop {
    display: flex;
  }

  .sidebar__wrapper--mobile {
    display: none;
  }
}

@media screen and (min-width: 992px) {
  .sidebar.sidebar--no-desktop {
    transform: translateX(0);
    display: none;
  }
}


.scroll-content {
  position: fixed;
}
