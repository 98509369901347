@import '../../../../scss/colors';

.field-input-threshold {
    display: flex;
    flex-direction: column;
    padding: 0 5px;

    .field-input {
        display: flex;
        justify-content: space-between;
        align-items: center;

        img {
            width: 20%;
        }

        input {
            width: 150px;
            width: 55%;
            height: 45px;
            border: 0 solid;
            border-color: transparent;
            margin-left: 5px;
        }


    }

    .field-indicator {
        color: $shamrock-green;
        min-width: 200px;
    }

}