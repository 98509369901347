.line2 {
    display: flex;
    justify-content: space-between;

    .ipsource {
        width: 23%;

    }

    .ipdestination {
        width: 24%;

    }

    .portsource {
        width: 16%;
    }

    .portdestination {
        width: 17%;
    }

    .protocol {
        width: 20%;
    }


}