@import "../../../../scss/colors";

.close-button {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 3px;
  border-radius: 3px;

  color: $cool-grey;
  cursor: pointer;

  &:hover {
    background-color: $silver;

  }
}
