@import '../../../../scss/colors';
@import '../../../../scss/fonts';

.exploration {
    display: flex;
    align-items: baseline;

    .explore {
        display: flex;
        align-items: baseline;
        cursor: pointer;

        div {
            margin-left: 4px;
            display: flex;
            align-items: center;

        }


        img {
            width: max-content;
        }

        p {
            width: 173px;
            height: 16px;
            font-family: $font;
            font-size: 14px;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            color: $bright-sky-blue;
        }

    }
}

.explore-menu-toogle {
    display: flex;
    width: 800px;
    height: max-content;
    padding: 20px;
    display: flex;
    justify-content: space-evenly;
    position: relative;
    background-color: #ffffff;

    .extra-content {
        text-align: center;
        display: flex;
        max-width: 100%;
        flex-direction: column;

        .click-message {
            font-style: italic;
            font-weight: bold;
            color: #a8c1e4;
        }
    }
}
