@import '../../../../scss/colors';
@import '../../../../scss/fonts';

.threath-severity {
    align-items: center;
    display: flex;
    text-align: center;
    padding: 5px;
    width: inherit;
    .text-content {
        margin-left: 5px;
        margin-top:1px;
        width: 33px;
        height: 16px;
        font-family: $font;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
    }
    .medium {
        color : $orange-nice ;
    }
    .high {
        color: $coral;
    }
}
