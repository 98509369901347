@import "../zoom";
.linear-gradient {
  width: 100%;
  height: 8px;
  border-radius: 1px;
  background-image: linear-gradient(to right, #00b8ec, #00355c);
}

.layout {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;

  .page-container {
    z-index: 1;
    background-color: $background-color-bright;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    position: relative;

    zoom: $layout-zoom;
    //Mozilla
    -moz-transform: scale($layout-zoom);
    -moz-transform-origin: 0 0;

    .content {
      flex-grow: 1;
      display: flex;
      padding: 10px 25px;
      flex-direction: column;
    }
  }

  .sidebar {
    z-index: 2;
  }
}
