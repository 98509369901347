@import "../../../../../scss/colors";

.hint-wrapper {
  background-color: $white;
  border-radius: 4px;
  border: solid 1px $silver;
  padding: 10px;

  .hint {


    .detail {
      .detail-title {
        font-size: 14px;
        color: $dark;
      }

      .detail-info {
        font-size: 16px;
        font-weight: bold;
        color: $dark;
      }

      .talker {
        display: flex;
        justify-content: space-between;
      }

      .talkers-ip{
        min-width:130px;
      }

      .talkers-count{
        margin-left: 5px;
      }
      
      .col {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0;
      }

      .space {
        padding: 0 5px 0 0;
      }
    }
  }
}

