@import '../../../../scss/colors';


.alerte-modal-button {
    display: flex;
    justify-content: flex-end;

    .alert-modal-content {
        display: flex;
        flex-direction: column;

    }



    .create-alertes-button {
        min-width: 125px;
        height: 36px;
        border-radius: 5px;
        background-color: $bright-sky-blue;
        color: white;
        border: transparent;
        font-size: 14px;
    }

}

.alert-modal {
    display: flex;
    align-items: center;
    justify-content: center;
}
