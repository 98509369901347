.number-filter {
    display: inline-flex;
}

.number-from,
.number-to {
    width: 100%;
}


.number-input {
    width: 60%;
}

.number-label {
    display: table-cell;
    width: 10%;
    padding: 5px;
}