@import "../../../../../scss/colors";

.gm-style {
  font-family: 'Source Sans Pro', sans-serif;

  .gm-style-iw {
    border-radius: 5px;
    padding: 0 !important;
    border: 1px solid $silver;

    .gm-style-iw-d {
      max-width: 100% !important;
      max-height: 100% !important;
    }

    .gm-ui-hover-effect {
      display: none !important;
    }
  }

  .gm-style-iw-t::after {
    border-left: 1px solid $silver;
    border-bottom: 1px solid $silver;
    background: white;
    z-index: 2;
  }
}


.mapHint {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;

  .hint-icon {
    width: 50px;
    margin: 20px;
  }

  .hint-message-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 20px 20px 20px 0;

    .hint-title {
      font-size: 18px;
      font-weight: bold;
      color: $dark;
      margin: 3px 0;
    }

    .hint-message {
      font-size: 18px;
      color: $dark;
      margin: 3px 0;
    }
  }


}

.gm-style-iw-c {
  // hint background color
  //background-color: transparent !important;
}