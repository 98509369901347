@import "../../../../scss/colors";

.submit-button {
  width: 320px;
  height: 40px;
  border-radius: 5px;
  background-color: $bright-sky-blue;
  color: $white !important;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  font-size: 18px;
  font-weight: bold;
}
