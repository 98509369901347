/*!
 * https://github.com/YouCanBookMe/react-datetime
 */
@import "../colors";

.rdt {
  position: relative;
}

.rdtPicker {
  display: none;
  position: absolute;
  top: 36px;
  right: -112px;
  border-radius: 5px;
  border: 1px solid $silver-two;
  overflow: hidden;
  width: 250px;
  z-index: 99999 !important;
  padding: 5px;
  background: $white;
  background-clip: padding-box;
  color: $steel;

  table {
    font-size: 14px;

    thead {
    }

    tbody {


    }
  }
}

.rdtOpen .rdtPicker {
  display: block;
}

.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static;
}

.rdtPicker .rdtTimeToggle {
  text-align: center;
}

.rdtPicker table {
  width: 100%;
  height: 100%;
  margin: 0;
  border-collapse: collapse !important;
}

.rdtPicker td,
.rdtPicker th {
  text-align: center;
  height: 30px;
}

.rdtPicker td {
  cursor: pointer;
}

.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: $calendarBlue;
  color: $white;
  border-radius: 3px;
  cursor: pointer;
}

.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
  color: lightgrey;
}

.rdtPicker td.rdtToday {
  // today date
  position: relative;
  background-color: $greyish;;
  border-radius: 3px;
  color: $white;
}

.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  //active date
  background-color: $calendarBlue;
  border-radius: 3px;
  color: $white;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff;
}

.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker td span.rdtOld {
  color: #999999;
}

.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker .dow {
  font-size: 12px;
  font-weight: 400;
  width: 14.2857%;
  border-bottom: none;
  cursor: default;
}

.rdtPicker th.rdtSwitch {
  color: $dark;
  font-size: 16px;
}

.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 21px;
  color: $dark;
  vertical-align: top;
}

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker thead tr:first-child {
  //header

  border-bottom: 1px solid $blue-grey;
}

.rdtPicker thead tr:first-child th {
  cursor: pointer;
}

.rdtPicker thead tr:first-child th:hover {
  //header hover
  background: $greyish;
  color: $white;
  border-radius: 3px;
}

.rdtPicker tfoot {
  border-top: 1px solid #f9f9f9;
}

.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer;
}

.rdtPicker button:hover {
  background-color: $greyish;
  border-radius: 3px;
  color: $white;
}

.rdtPicker thead button {
  width: 100%;
  height: 100%;
}

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer;
}

td.rdtMonth:hover,
td.rdtYear:hover {
  background: $calendarBlue;
  color: $white;
  border-radius: 4px;
}

.rdtCounters {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rdtCounters > div {
  float: left;
}

.rdtCounter {
}

.rdtCounter {
  width: 40px;
}

.rdtCounterSeparator {
}

.rdtCounter .rdtBtn {
  height: 40%;
  line-height: 40px;
  cursor: pointer;
  display: block;

  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}

.rdtCounter .rdtBtn:hover {
  background: $calendarBlue;
  border-radius: 3px;
  color: $white;
}

.rdtCounter .rdtCount {
  font-size: 1.2em;
  margin: 2px 0;
}

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px;
}

.rdtMilli input {
  width: 100%;
  font-size: 1.2em;
  margin-top: 37px;
}

.rdtTime td {
  cursor: default;
}

.rdt-input {
  background-color: transparent;
  border: none;

  font-size: 18px;
  text-align: center;
  color: $dark-indigo;

}

// calendar buble arrow
.rdtPicker:before {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;

  border-color: rgba(194, 225, 245, 0);
  border-bottom-color: $calendarBlue;
  border-width: 7px;
  margin-left: -79px;
}

.rdtPicker-time {
  .rdtPicker:before {
    border-color: rgba(194, 225, 245, 0);
    border-bottom-color: $greyish !important;
    border-width: 7px;
    margin-left: -52px;
  }
}

.picker-icon {
  position: relative;
  width: 15px;
  height: 15px;
  margin: 10px;
}

