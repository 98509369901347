.date-range-wrapper {
    position: relative;
    width: 100% !important;
}

///smart table ovverride css
.rdrMonth {
    width: 100% !important;
}

// solves issue with data range
.ReactTable>.rt-table>.-filters>.rt-tr>.rt-th {
    padding: 0;
    overflow: visible !important;
}

// solves when date range activates overflow slider inside table
.rt-table {
    overflow: unset !important;
}

// START improve date range design
.date-range-input {
    border-radius: 0;
    height: 35px;
    width: 100% !important;
}

.react-daterange-picker {
    z-index: 1;
    background-color: white;
}

.react-daterange-picker__inputGroup {
    height: 15px;
}

.react-daterange-picker__wrapper {
    border: none !important;
    color: black;
}

// border radius
.react-daterange-picker {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px !important;
}

.react-daterange-picker__inputGroup__input {
    border: none !important;
}

// remove padding from button and input to get some space in column
.react-daterange-picker__clear-button,
.react-daterange-picker__calendar-button,
.react-daterange-picker__inputGroup__input {
    padding-left: 2px !important;
    padding-right: 2px !important;
}

// get space in column
.react-daterange-picker__inputGroup {
    min-width: 10px !important;
}

// input placeholders
.react-daterange-picker__inputGroup__month::-webkit-input-placeholder::before {
    color: #666;
    content: "Line 1\A Line 2\A Line 3\A";
}


// END improve date range design