@import "../../../../../scss/colors";
@import "../../../../../scss/zoom";

.autosize{
  $inverse-zoom: 1/$layout-zoom;
  zoom: $inverse-zoom;
}

.graph {
  flex-grow: 1;
  display: flex;
  flex-direction: column;


  .graph-title {
    display: inline-block;
    align-items: center;
    justify-content: flex-start;
    font-size: 24px;
    margin: 5px 5px 10px 16px;
    font-weight: bold;
    color: $dark-indigo;
  }

  .graph-title-disclaimer {
    //display: inline-block;
    align-items: center;
    justify-content: flex-start;
    margin: 5px 5px 25px 16px;
    font-size: 16px;
    font-weight: normal;
    color: $dark-indigo;
  }

  .graph-container {
    flex-grow: 1;
    margin-bottom: 24px;

    .rv-xy-plot__axis__tick__text {
      font-size: 18px;
      line-height: 1.33;
      color: $dark-indigo;
    }

  }
}

