@import "settings/variable";
//themes' colors
//base...
@import "generic/normalize.scss";
@import "generic/box-sizing.scss";
@import "./base/base";
@import "./base/scaffolding";
// styles of base elements
@import "./base/typography";
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,700&display=swap');
// base styles of h1-h6, p, span
//components...
@import "component/muiInput";
@import "component/btn";
@import "./component/card";
@import "./component/sidebar";
@import "./component/modal";
@import "./component/topbar";
@import "./component/load";
@import "./component/form";
@import "./component/modal";
@import "./component/notification";
@import "./component/vendor";
@import "./component/react-datetime";
@import "./component/graph";
@import "../shared/components/SmartForm/smart-form.scss";
@import "../shared/components/form/ColorPicker/color-picker.scss";
//objects...
@import "objects/layout.scss";
// containers
@import "./containers/login";
@import "./containers/deviceManagement";
@import "./containers/visibilityView";
@import "./containers/visibilityViewNew";
@import "./containers/visibilityViewBackground";


@import "containers/summaryView";
// shared styles
@import "./containers/ipGroupManagement";


@import "./common/common";
