.line1 {
    display: flex;
    justify-content: space-between;

    .rdt-input {
        background-color: white;
        border: none;
        font-size: 12px;
        text-align: center;
        fill: green;
    }

    .picker-icon {
        position: absolute;
        width: 15px;
        height: 15px;
        margin: 10px;
        color: black;
        z-index: 100;
        margin-top: 0;
    }

    .input-time-unit {
        display: flex;
        justify-content: center;
        text-align: right;
        height: inherit;
        align-items: center;

    }

    .input-time-unit .time-units-input {
        height: 100%;
        width: 60%;
        background-color: transparent;
        border: none;
        text-align: center;
        color: black;
    }

    .dates-section {
        width: 27%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        .dateFrom {
            width: 48%;
        }

        .dateTo {
            width: 48%;
        }

        .vv-pickers-separator {
            width: 14px;
            margin: 15px 0 0 0;
        }

    }

    .timeRange{
        width: 10%;
    }

    .timeunit {
        width: 10%;
        height: inherit;
    }

    .queryType {
        width: 16%;

    }

    .devices {
        width: 37%;
    }

    // Medium Screen settings
    .medium-screen-dates-section {
        @extend .dates-section;
        width: 32%;
    }

    .medium-screen-queryType {
        @extend .queryType;
        width: 20%;
    }

    .medium-screen-devices {
        @extend .devices;
        width: 28%;
    }

    // Small Screen settings
    .small-screen-dates-section {
        @extend .dates-section;
        width: 36%;
    }

    .small-screen-queryType {
        @extend .queryType;
        width: 20%;
    }

    .small-screen-devices {
        @extend .devices;
        width: 24%;
    }
}
