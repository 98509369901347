@import '../../../scss/colors';

.delete-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 30px 100px;

  .delete-modal-header {
    display: flex;
    flex-direction: column;

    .header-image {
      height: 200px;
    }

    .close-button {
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }

  .delete-modal-text {
    font-size: 20px;
    font-weight: bold;
    color: $dark;
    margin-bottom: 10px;
  }

  .delete-modal-disclaimer {
    font-size: 20px;
    color: $dark;
    margin-bottom: 20px;
  }

  .button {
    height: 45px;
    width: 280px;
    font-size: 18px;
    margin: 5px;
  }

  .delete-button {
    font-weight: bold;
    background-color: #FF6969 !important;
  }

  .cancel-button {
    //background-color: transparent;
    color: $cool-grey;
  }
}