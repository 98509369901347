@import '../../../../scss/colors';
@import '../../../../scss/fonts';


.card-container-page {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    flex: 1;
    margin: 0.2em;

    //padding: 0px 20px;

    .custom-card {
        width: 420px;
        height: 210px;
        padding: 15px;
        border-radius: 5px;
        box-shadow: 0 2px 7px 0 rgba(133, 133, 133, 0.55);
        background-color: $white;
        color: black;

        .button {
            background: transparent;
            border-color: transparent;
            border: 0;
            margin: 0;
            color: black;
            float: right;
            text-decoration: underline;
        }
    }

}

.cart-section-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 10px;
}

.card-tags-section {
    margin-left: 3%;
    display: flex;
    align-items: center;
    height: 10px;

    .card-tag {
        margin-right: 8px;
        border-radius: 6px;
        background-color: #EEF4FC;
        color: #1553AA;
    }
}

.card-top-section {
    margin: 0 auto 20px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .card-top-section-title {
        width: 70%;
        font-family: $font;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.89;
        letter-spacing: 0.4px;
        color: $dark;
        float: left;
        margin-left: 3%;

    }

    .card-top-section-edit {
        text-decoration: underline;
        width: 35%;
        font-family: $font;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 0.36px;
        color: $dark;
        float: right;
        text-align: right;
        margin-right: 5.5%;
    }

}


.card-center-section {
    clear: both;
    margin-left: 3%;
    margin-right: 15%;
    min-height: 20px;
    font-size: 12px;

    .p {
        width: 100%;
        font-family: $font;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: 0.3px;
        color: $dark;
        margin-left: 5.5%;
    }
}


.card-botton-section {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-bottom: 20px;
    justify-content: start;



}

.others-ip {
    text-decoration: underline;

    font-family: $font;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.36px;
    color: $dark;

    p {
        width: 69px;
        height: 16px;
        font-family: $font;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 0.31px;
        color: $dark;
        margin: 0;
    }
}
