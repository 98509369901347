@import '../../../../../../scss/colors.scss';
@import '../../../../../../scss/fonts.scss';

.alert-ip {
  width: 55%;
}

.alert-port {
  width: 45%;
}

.alert-enrichment {
  width: 16%;
}

.react-select-redux-forms-extra {
  height: 70px !important;
}
