@import '../../../../../scss/colors';

.make-removable {
    display: flex;
    align-self: center;
    background-color: transparent;
}


.remove-button-sms {
    border: 0px solid transparent;
    align-self: center;
    background-color: transparent;
}

.remove-button-email {
    border: 0px solid transparent;
    align-self: flex-end;
    background-color: transparent;
    font-size: 20px;
}

.remove-button:hover {
    background-color: $cool-grey;
}