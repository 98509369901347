@import '../../../../scss/fonts';
@import '../../../../scss/colors';

.alerte-page-container{
    background-color: #f0f0f0;
    height: 90%;
    .alert-page-top-container{
        display: flex;
        flex-direction: row-reverse;
        height: 1000px;
    }
}

.section-filter {
    display: flex;
    justify-content: flex-end;
    padding: 25px 25px 0 25px;
}
.section-header {
    align-self: flex-start;
    font-family: $font;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.44px;
    color: $dark-blue;
    margin: 10px;
    padding: 25px 25px 0px 25px;
}
