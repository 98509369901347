.email-recipient{
    width: 100%;  
    height: 40px;
    margin : 10px 0 0 10px; 
    .MuiFormControl-root {
        
        border: 0;
        margin: 0;
        display: inline-flex;
        padding: 0;
        position: relative;
        min-width: 100%;
        flex-direction: column;
        vertical-align: top;
    }    
  }