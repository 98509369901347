@import '../../../../../scss/colors';
@import '../../../../../scss/fonts';

.tag-line {
    min-height: 30px;
    max-height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.tag-logo-left {
    text-align: center;
    width: 25px;
    height: auto;
}

.tag-port-text {
    max-height: 40px;
    font-family: $font;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.31px;
    color: $white;
    margin: 3px auto;
    margin-left: 5px;
}

.tag-logo-left:hover {
    color: white;
    background-color: #0987ab;
}

.tag-text {

    height: 14px;
    font-family: $font;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.31px;
    color: $white;
    flex-wrap: wrap;
}

.tag-line-sign {
    background: transparent;
    border-color: transparent;
    margin-right: 5px;
    width: 14px;
    height: 20px;
    z-index: 10;

}

.baseline {

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: 20px;
    flex-direction: row;
    font-family: $font;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.26px;
    color: $white;
    height: 65px;
    border-radius: 5px;
    background-color: $bright-sky-blue;

    .baseline-text {
        flex: 4;
        margin: 10px auto;
    }

    .baseline-image {
        flex: 1;
        margin: 15px auto;

    }

}


.advance-feature-box {
    color: black;
    text-decoration: underline;
}

.advance-feature-box :hover {
    cursor: pointer;
}


.advance-feature {
    width: 165px;
    height: 16px;
    font-family: $font;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.89;
    letter-spacing: 0.4px;
    color: black;

    img {
        width: 10%;
    }
}