@import "../../../scss/colors";

.form-layout {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .form-wrapper {
    flex-grow: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .form-holder {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      .form-logo {
        width: 108px;
        height: 67px;
        background-image: url('../../../assets/login/logo-vertical.png') !important;
        background-size: 100% 100%;
        margin-bottom: 60px;
      }

      .form-welcome {
        font-size: 24px;
        color: $bright-sky-blue;
      }

      .form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .form-title {
          font-size: 45px;
          font-weight: bold;
          color: $dark-indigo;
          margin-bottom: 20px;
        }

        .form-field {
          margin-bottom: 10px; // !important;

        }

        .form-error {
          color: red;
          font-size: 12px;
        }

        .error-message-icon {
          width: 80px;
          margin: 13px;
        }

        .form-submit {
          font-size: 14px;
          font-weight: 500;
          width: 320px;
          height: 40px;
          margin-top: 20px;
        }
      }
    }
  }

  .layout-image {
    flex-grow: 1;
    height: 100%;
    background-image: url('../../../assets/login-bg.png') !important;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}
