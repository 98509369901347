@import "../../../../../scss/colors";
@import '../../../../../scss/fonts';
.sms-recipients{
    flex-direction: column;
    width: 100%; 
    justify-content: center;
}

.sms-add-recipient{
    //mouse pointer
    //color blue (from the style guide)
    margin-top: 20px;
    width: 320px;
    height: 22px;
    align-self: flex-end;
    font-family: $font;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: 0.3px;
    color: $bright-sky-blue;
}

.sms-add-recipient:hover { 
    color: $cool-grey;

}