@import '../../../../scss/colors';
@import '../../../../scss/fonts';

.section-container {
    display: flex;
    flex-direction: column;
    margin: 0px 25px 0 25px;
    position: relative;
    overflow: auto;
    height: 900px;
    border-bottom: 2px solid #8585858c;
    .section-filter{ 
        margin: 0 0 20px 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    

    .section-body {
        width: 100%;
        height: 100%;
        max-height: 1000px;
    }
}