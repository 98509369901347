.deliver-on {
    display: flex;
    flex-direction: column;

    .deliver-on-header {
        display: flex;

    }

    .deliver-on-group-button {
        display: flex;
        flex-direction: column;

        .deliver-on-time {
            display: flex;
            width: 50%;
        }

        .deliver-on-timezone {
            width: 45%;

        }
    }
}