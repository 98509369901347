.input-time-unit {
    display: flex;
    justify-content: center;
    text-align: right;
    height: inherit;
    color: #425262;
    .time-units-input {
        height: 100%;
        width: 50%;
        background-color: transparent;
        border: none;
        text-align: center;
        color: #425262;
    }
    .unit-value {
        align-self: center;
    }
}