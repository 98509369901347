@import '../../../../../scss/colors';
@import '../../../../../scss/fonts';
.email-recipients{
    flex-direction: column;
    width: 100%; 
}

.email-add-recipient{
    margin-top: 20px;
    height: 22px;
    align-self: flex-end;
    font-family: $font;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: 0.3px;
    color: $bright-sky-blue;
    cursor: pointer;
    //mouse pointer
    //color blue (from the style guide)

}
.email-add-recipient:hover { 
    color: $cool-grey;

}