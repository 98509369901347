.sms-recipient{
    .sms-recipient-value{
        margin : 0 10px 0 0;
        display: flex;
        flex-wrap: wrap;
        .MuiFormControl-root {
            height: 40px;
            margin: 0 10px 0 10px;
        }
     }
}