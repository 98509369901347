@import '../../../../scss/settings/variable';

.enrichment-button, .more-options-button {
    font-size:12px;
    width: 100%;
    text-align: center;
    background-color: #071c36;
    border: 1px solid $bright-sky-blue;
    color: $white;
    border-radius: 5px;
    height: 40px;
}

.alertes-content {

    width: 1000px;
    border-radius: 5px;
    box-shadow: 0 2px 7px 0 rgba(133, 133, 133, 0.55);
    background-color: #071c36;


    .create-alerte-close{
        justify-content: flex-end;
        display: flex;
        margin: 10px;
        img {
            width: auto;
        }
    }

    .alertes-header {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .alert-modal-title {
            color: #a8c1e4;
            font-size: 30px;
            font-weight: bold;
            margin-left: 40px;
        }

        img {
            width: 15%;
            align-self: center;
        }
    }

    .alertes-body {
        display: flex;
        flex-direction: column;
        margin: 30px;

        .create-alert-bottom {
            margin-top: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 8px;

            .checkbox-filters {
                width: 40%;
            }

            .more-options-buttons {
                display: flex;
                width: 58%;
                justify-content: space-between;
            }

            .enrichment-button, .more-options-button {
                margin-right: 10px;
                width: 31%;
            }

            .submit-alert-button {
                width: 240px;
                height: 45px;
                border-radius: 5px;
                box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
                background-color: #00b8ec;
                text-align: center;
                color: #ffffff;
                border: 0;
                font-weight : bold;
            }
        }

        .save-alert {
            display: flex;
            margin-top: 20px;
            width: 100%;
            justify-content: center;

            .save-alert-button {
                width: 25%;
            }
        }
    }
}
