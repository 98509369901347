@import '../../../../../scss/colors';
@import '../../../../../scss/fonts';

.switch-container {
    display: flex;
    align-items: center;

    .switch-label {
        height: 16px;
        opacity: 0.3;
        font-family: $font;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
    }

    .cynamics-switch {
        .MuiSwitch-switchBase {
            color: $bright-sky-blue;
        }

        .MuiSwitch-colorSecondary.Mui-checked+.MuiSwitch-track {
            background-color: $bright-sky-blue;
        }

        .Mui-checked {
            color: $bright-sky-blue;
        }

        .MuiSwitch-track {
            border: 1px solid;
            background-color: $white;
            border-color: $bright-sky-blue;
        }

        .MuiSwitch-thumb {
            background-color: $white;
            margin-top: 3px;
            margin-left: 3px;
            width: 12px;
            height: 12px;
        }
    }

}
