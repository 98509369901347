@import "../../../scss/colors";

.infobox {
  flex-basis: 0;
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin: 5px;
  padding: 5px;
  background-color: $dark-indigo;
  border-radius: 5px;

  .icon {
    width: 35px;
    margin: 15px;
  }

  .info-wrapper {
    padding: 3px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .info {
      line-height: 1.1;
      font-size: 44px;
      color: $white;
      font-weight: bold;

      .info-loader {
        color: #6E7175
      }
    }

    .desc {
      font-size: 14px;
      color: $white;
    }
  }
}
