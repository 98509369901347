@import "../colors";
// override material style
.MuiInput-underline:after,
.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom-width: 1px !important;
  border-bottom-color: $dark;
  //border-color: darkgrey !important;
}

// override material style
.MuiFormLabel-root.Mui-focused.Mui-error,
.MuiInput-underline:after,
.MuiInput-underline:hover:not(.Mui-disabled):before {
  //color: black !important;
  border-bottom-color: $dark;
  border-bottom-width: 1px !important;
  //border-color: darkgrey !important;
}

