div.additional{
    font-size:20px;
}

div.thankyou-box{
    padding-top: 20px;
}

div.form-logo{
    margin-bottom: 15px !important;
}

div.form-holder{
    min-height:500px;
}

div.thank-you-text {
    font-size: 20px;
    padding-bottom: 20px;
}