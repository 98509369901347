@import '../../../../../scss/colors';
@import '../../../../../scss/fonts';



.colaper {
    font-family : $font;
    .MuiTableCell-root {
        padding: 0px;
    }

    .MuiTableHead-root {
        background: $dark-indigo;

    }

    .MuiTableCell-head {
        color: white;
    }

    th {
        width: 30px;
        height: 50px;
        font-weight: 700;
        font-size: 14px;
    }
}
