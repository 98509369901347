@import '../../../../../scss/colors';
@import "../../../../../scss/zoom";
.popover {
    background: transparent !important;
    border: 0px transparent !important;
}

div.add-button{
    min-width: 50px !important;
    max-width: 50px !important;
    max-height:45px;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
    padding-left: 6px;
}

.background-process-container { 
    display:  flex;
    align-items: center;
    z-index: 9000;
}
background-process-button:hover{
    background: #1fd2ff !important;
    cursor: pointer;
}

.remove-all-process-button{
    background-color: #FF6969 !important;
}
.remove-all-process-button:hover{
    background-color: red !important;
}
.background-process-content , 
    .background-process-header{
        .background-process-rectangle {
            margin: 5px;
            width: 240px;
            height: 50px;
            border-radius: 8px;
            background-color: $dark-indigo;
            display: flex;
            flex-direction: row;
            align-items: bottom;
            justify-content: space-evenly;
            .section-new-remove { 
                display: flex;
            }
            .background-process-Name {
                flex: 5;
                height: auto;
                margin-top: 5px;
                object-fit: contain;
                cursor: pointer;
                color: $white;
            }
            .background-process-running {
                flex :1; 
                height: auto;
                margin-top: 5px;
                object-fit: contain;

            }
            .background-process-arrow {
                flex :1; 
                align-self: flex-start;
                height: auto;
                margin-top: 10px;
                object-fit: contain;
            }

            .background-process-more {
                background-color: 'transparent';
                width : 10px; 
                margin-top: 10px;
                .svg{
                    margin-left: -25px;
                }
            }
            
        }
    }

  


    