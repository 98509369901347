.sus-connection-top { 
    display: flex;
    flex-direction: column;
}

.alias-popover-anchor-element {
    display: flex;
    &:hover {
        color: #00B8EC;
        cursor: pointer;
    }
}