@import "../colors";

$color-main-blue: #326c91;
$color-main-black: #4a4a4a;
$color-main-gray: #a4a4a4;
$color-main-light-gray: #dddddd;
$color-main-white: #ffffff;

$color-accent: #2e3a48;
$color-accent-hover: darken($color-accent, 10%);
$color-additional: #999999;
$color-additional-hover: darken($color-additional, 10%);

$color-yellow: #f6da6e;
$color-yellow-hover: darken($color-yellow, 10%);

$color-violet: #c88ffa;

$color-red: #ff4861;
$color-red-hover: darken($color-red, 10%);

$color-blue: #70bbfd;
$color-blue-hover: darken($color-blue, 10%);

$color-gray: #787985;
$pale-gray: #f5f9fc;

$color-btn-red: #e83837;
$color-btn-red-hover: darken($color-btn-red, 10%);

$duck-egg-blue: #f2f8fd;
$pale-grey-two: #eaeef5;
$brownish-grey: #646363;
$greyish: #a9a9a9;
$dirty-white: #f8f8f8;
$light-blue-grey: #F0F4F6;
$background-color: $dark-indigo;
$background-color-bright: $off-white;


// Zeplin colors
$main-background: #00355c;
$second_background: #1F4D6F;

$header-height: 3.7em;

$themes: (light: (colorBackground: white,
        colorBackgroundBody: white,
        colorText: #646777,
        colorTextAdditional: #646777,
  // logoImg: url(../shared/img/logo/logo_light.svg),
        colorHover: $light-blue-grey,
        colorBorder: #eff1f5,
        colorIcon: #dddddd,
        imgInvert: invert(0%),
        colorFieldsBorder: #f2f4f7,
        colorBubble: rgba(242, 244, 247, 0.65),
        colorBubbleActive: rgba(234, 238, 255, 0.6),
        colorScrollbar: #B4BFD0,
        colorFitness: #646777,
),
        dark: (colorBackground: #232329,
                colorBackgroundBody: #2a2a31,
                colorText: #dddddd,
                colorTextAdditional: #999999,
          // logoImg: url(../shared/img/logo/logo_dark.svg),
                colorHover: #38373f,
                colorBorder: #333246,
                colorIcon: #605f7b,
                imgInvert: invert(100%),
                colorFieldsBorder: #33333a,
                colorBubble: rgba(68, 79, 97, 0.65),
                colorBubbleActive: rgba(92, 104, 156, 0.6),
                colorScrollbar: #606071,
                colorFitness: #ffffff));

@mixin themify($themes) {

  @each $theme,
  $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;

      @each $key,
      $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge($theme-map, ($key: $value)) !global;
      }

      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}
