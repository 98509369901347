.alertes-inputs-line2 {
    display: flex;

    .alerte-devices {
        width: 100%;
    }

    .device-selector-field {
        width: 100%;
        height: 100%;

        .css-yk16xz-control {
            height: 100%;
        }
    }

    .alerte-type-field {
        width: 100%;
        height: 100%;

        .css-yk16xz-control {
            height: 100%;
        }
    }
}