$bright-sky-blue: #00b8ec;
$light-indigo: #043e92;
$dark-indigo: #071c36;
$darker-indigo: #050c19;

$blue: #326c91;
$blue-grey: #7287a5;

$dark-blue : #526f96; 
$grey-bleu : #48648b; 
$light-blue-grey: #a8c1e4;
$greyish: #8391A7;
$black: #4a4a4a;
$gray: #a4a4a4;
$cool-grey: #9ca0a4;
$steel: #848390;
$silver: #dbdfe0;
$silver-two: #dce2e2;
$light-gray: #dddddd;
$white: #ffffff;
$dark: #2e3a48;
$coral: #f05f5a;
$shamrock-green: #00df5d;
$off-white: #f0f0f0;


$orange-nice : #ecaa00; 


$calendarBlue: #02BBF1;
$calendarDarkerBlue: #0188B6;