@import "../colors";
@import "../fonts.scss";


.group-view-container {
  display: flex;
  height: 100%;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}

.group-top-segments {
  display: flex;
  flex-direction: column;
  padding: 0 5px 5px;

}

.ipgroup-overlay {
  height: '96%';
  overflow: overlay
}

.top-section {
  display: flex;
  justify-content: space-between;
  padding-button: 1vh;
  padding-right: 1vh;

  .define-group-text {
    font-size: 20px;
    font-weight: bold;
    color: $dark-indigo;
    margin-left: 5px;
  }
}

.group-ip-item {

  border-radius: 4px;
  background-color: var(--bright-sky-blue);

  .group-ip-text {

    font-family: $font;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.31px;
    color: var(--white);

  }
}

.group-card {
  width: 400px;
  height: 210px;
  border-radius: 5px;
  box-shadow: 0 2px 7px 0 rgba(133, 133, 133, 0.55);
  background-color: var(--white);

  .group-edit {
    width: 28px;
    height: 16px;
    position: right;
    font-family: $font;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.36px;
    color: var(--dark);

  }

  .group-title {
    position: left;
    font-family: $font;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.89;
    letter-spacing: 0.4px;
    color: var(--dark);
  }

  .group-description {
    font-family: $font;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: 0.3px;
    color: var(--dark);

  }

  .group-other {
    width: 69px;
    height: 16px;
    font-family: $font;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.36px;
    color: var(--dark);

  }

  .group-card-top {
    margin-block: 10 10 10 10;
  }

  .group-card-bottom {
    margin: 20 20 20 20
  }
}
