$dark: #2e3a48;
$bright-sky-blue: #00b8ec;
$dark-indigo: #071c36;
$cool-grey: #9ca0a4;
$coral: #f05f5a;
$off-white: #f0f0f0;
$blue-grey: #7287a5;
$silver: #dbdfe0;
$white: #ffffff;
$silver-two: #dce2e2;
$steel: #848390;
$light-blue-grey: #a8c1e4;
$shamrock-green: #00df5d;

.notif-background-process {
    width: 250px;
    border-radius: 8px;
    background-color: $dark-indigo;
    display: flex;
    flex-direction: column;
    align-self: center;
    z-index: 10;
    padding: 10px 0;
    .notif-upper-section {
        display: flex;
        justify-content: space-evenly;
        .text-input {
            box-sizing: border-box;
            background: transparent;
            border: transparent;
            border-bottom: 1px white solid;
            color: white;
            font-size: 16px;
            text-align: center;
        }

        .text-input:focus {
            box-shadow: none;
        }

        .notif-upper-section-indicator {
            width: 18px;
            height: 18px;
            object-fit: contain;
        }
        .notif-upper-section-input {
            width: auto;
        }
        .notif-upper-section-arrow {
            width: 8px;
            object-fit: contain;
            transform: rotate(-360deg);
            opacity: 0.41;
        }
        .notif-upper-section-more {
            width: 4px;
            height: 19px;
            object-fit: contain;
        }
    }
    .notif-lower-section {
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        align-self: center;

        .notif-button {
            font-size: 16px;
            border-radius: 5px;
            color: white;
            border: 0px;
            margin: 0 10px;
            padding: 8px 16px;
        }

        .action-save {
            background-color: $bright-sky-blue;
        }

        .action-cancel {
            background-color: $coral;
        }
    }
}

.Rectangle {
    width: 300px;
    height: 149px;
    border-radius: 8px;
    background-color: $dark-indigo;
}
